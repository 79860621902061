import React, { useEffect, useRef, useState } from "react";
import { TEST_URL, FORMAL_URL } from "./constants/router";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import { HttpClientConfigProvider } from "react-http-fetch";
import styled from "styled-components";
import Home from "./page/home";
import UserAgreement from "./page/userAgreement"
import "./react-i18next-config"
import 'antd/dist/reset.css';
import "./App.css"
import { isFormal } from "./constants/router";
import { useRotuRreferralId } from "./utils/help";

function httpResponseParser(res) {
  return res.json();
}

export default function App() {
  const httpReqConfig = {
    baseUrl: isFormal ? FORMAL_URL : TEST_URL,
    responseParser: httpResponseParser,
    reqOptions: {
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
    },
  };

  const isReferralIdPage = useRotuRreferralId()
  const pathname = window.location.pathname

  return (
    <>
      <HttpClientConfigProvider config={httpReqConfig}>
        <Routes>
          <Route path="/" element={<Home />} />
          {
            !isReferralIdPage && (
              <Route path={pathname} element={<Home />} />
            )
          }
          <Route path="/ottrade" element={<UserAgreement />} />
        </Routes>
      </HttpClientConfigProvider>
    </>
  );
}
