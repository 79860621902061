/**
 * 官网脚注
 * 2022年5月7日
 */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "../../hooks/useWindowSize";
import { language, imgUrl } from "../../utils/help";
import i18n from "i18next";


const googlePlay = imgUrl + "darkgooglePlay.png"
const android = imgUrl + "darkandroid.png"
const apple = imgUrl + "darkapple.png"
const fcback = imgUrl + "fcback.png"
const mail = imgUrl + "mail.png"
const telegram = imgUrl + "telegram.png"
const twitter = imgUrl + "twitter.png"
const youtube = imgUrl + "youtube.png"
const logo = imgUrl + "shareTttile.png"
const whatsapp = imgUrl + "whatsapp.png" 
export default function Foot() {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  useEffect(() => {
    const lan = language();
    i18n.changeLanguage(lan);
  }, []);
  const openUrl = (url) => {
    window.open(url)
  }
  return (
    <>
      <div style={{ display: 'flex', alignContent: "center", justifyContent: "center", marginTop: 230, marginBottom: 40, position: 'relative', zIndex: 1000 }}>
        <div style={{ height: 240 }} className="integrate">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {
              !isMobile && (
                <>
                  <img src={logo} style={{ height: 32 }}></img>
                  <div style={{ width: 3, height: 28, marginLeft: 16, marginRight: 16, backgroundColor: '#5A5C7B', borderRadius: 2 }}></div>
                  <div style={{ color: '#BDB9CE', fontWeight: "bold" }}>©️ <span style={{ fontSize: 28 }}>2023</span></div>
                  <div style={{ width: 3, height: 28, marginLeft: 16, marginRight: 16, backgroundColor: '#5A5C7B', borderRadius: 2 }}></div>
                  <div style={{ display: "flex", alignContent: "center", justifyContent: "space-between" }}>
                    <img src={telegram} style={{ width: 32, height: 32, cursor: "pointer", marginRight: 15 }} onClick={() => openUrl("https://t.me/Otrading_signals")}></img>
                    <img src={twitter} style={{ width: 32, height: 32, cursor: "pointer", marginRight: 15 }} onClick={() => openUrl("https://twitter.com/otradingio")}></img>
                    <img src={youtube} style={{ width: 32, height: 32, cursor: "pointer", marginRight: 15 }} onClick={() => openUrl("https://www.youtube.com/@otradingio")}></img>
                    <img src={mail} style={{ width: 32, height: 32, cursor: "pointer", marginRight: 15 }} onClick={() => openUrl("mailto:business@ottradex.com")}></img>
                    <img src={whatsapp} style={{ width: 32, height: 32, cursor: "pointer", marginRight: 15 }} onClick={() => openUrl("https://whatsapp.com/channel/0029Varm6S14tRryYPgLnj0m")}></img>
                  </div>
                </>
              )
            }
          </div>
          <div style={{ display: "flex", alignContent: "center" }}>
            <img src={googlePlay} style={{ width: 160, height: 50, cursor: "pointer" }} className="iconHover" onClick={() => openUrl("https://play.google.com/store/apps/details?id=ea.Ottradex.org")}></img>
            <img src={apple} style={{ width: 160, height: 50, marginLeft: 20, cursor: "pointer" }} className="iconHover" onClick={() => openUrl("https://testflight.apple.com/join/gHgPyqaC")}></img>
            <img src={android} style={{ width: 160, height: 50, marginLeft: 20, cursor: "pointer" }} className="iconHover" onClick={() => openUrl("https://ottrade.s3.ap-southeast-1.amazonaws.com/ant/install/android/ottrade.apk")}></img>
          </div>
        </div>
      </div>
    </>
  );
}


